import * as echarts from 'echarts';

// 折线图
function lineChart({
	source = {
		date: [], // x轴
		csi: [], // y轴，沪深300
		target: [], // y轴，标的数据
	},
	dom
}) {
	dom = typeof dom == 'object' && dom || document.querySelector(dom)
	const { date, target, csi } = source
	let myChart = echarts.init(dom)

	let option = {
		// tooltip: {
		// 	trigger: 'axis'
		// },
		legend: {
			left: -3,
			data: [
				{
					name: '策略',
					icon: 'rect'
				},
				{
					name: '沪深300',
					icon: 'rect'
				},
			],
			itemWidth: 25, //矩形宽度
			itemHeight: 6, //矩形高度
		},
		grid: {
			backgroundColor: 'transparent',
			left: '3%',
			right: '4%',
			bottom: '3%',
			top: '20%',
			containLabel: true
		},
		// toolbox: {
		// 	feature: {
		// 		saveAsImage: {}
		// 	}
		// },
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: date
		},
		yAxis: {
			type: 'value'
		},
		series: [
			{
				symbol: 'none',
				name: '策略',
				type: 'line',
				// stack: '总量',
				data: target,
				color: 'red'
			},
			{
				symbol: 'none',
				name: '沪深300',
				type: 'line',
				// stack: '总量',
				data: csi,
				color: 'blue'
			},
		]
	};

	myChart.setOption(option);
	return myChart
}

// 首页折线图
function lineChartForHome({
	source = {
		date: [], // x轴
		csi: [], // y轴，沪深300
		volume: [], // 成交量
		target: [], // y轴，标的数据
	},
	dom
}) {
	dom = typeof dom == 'object' && dom || document.querySelector(dom)
	const { date, target, csi } = source
	let myChart = echarts.init(dom)

	let option = {
		// legend: {
		// 	left: -3,
		// 	data: [
		// 		{
		// 			name: '策略',
		// 			icon: 'rect'
		// 		},
		// 		{
		// 			name: '沪深300',
		// 			icon: 'rect'
		// 		},
		// 	],
		// 	itemWidth: 25, //矩形宽度
		// 	itemHeight: 6, //矩形高度
		// },
		grid: {
			backgroundColor: 'transparent',
			left: '3%',
			right: '4%',
			bottom: '3%',
			top: '20%',
			containLabel: true
		},
		// toolbox: {
		// 	feature: {
		// 		saveAsImage: {}
		// 	}
		// },
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: date
			},

		],
		yAxis: {
			type: 'value'
		},
		series: [
			{
				symbol: 'none',
				name: '指数',
				type: 'line',
				data: target,
				color: 'red'
			},
			{
				symbol: 'none',
				name: '沪深300',
				type: 'line',
				data: csi,
				color: 'blue'
			},
			{
				symbol: 'none',
				name: '成交量',
				type: 'bar',
				data: volume,
			},
		]
	};

	myChart.setOption(option);
	return myChart
}

export {
	lineChart
}