<template>
  <section class="tactics_item">
    <i v-show="false">{{ i = item.strategy_detail }}</i>
    <div class="title">
      <div class="title_left">
        <span>策略：</span>
        {{ i.name }}
      </div>
      <div class="title_right">
        <span :class="getClass(i.total_gain)">{{ i.total_gain }}%</span>
        <span>总收益</span>
      </div>
    </div>
    <div class="inner">
      <div class="echart" :id="`echart_${item.strategy_id}`" @click.stop=""></div>
      <p class="about">{{ i.description || '暂无说明...' }}</p>
      <div class="row">
        <div class="row_left">
          今日收益：<span :class="getClass(i.daily_gain)">{{ i.daily_gain }}%</span>
        </div>
        <div class="row_right">
          过去一月：<span :class="getClass(i.monthly_gain)">{{ i.monthly_gain }}%</span>
        </div>
      </div>
      <div class="row">
        <div class="row_left">
          过去一年：<span :class="getClass(i.last_year_gain)">{{ i.last_year_gain }}%</span>
        </div>
        <div class="row_right">
          近一年最大回撤：<span>{{ i.max_down }}</span>
        </div>
      </div>
      <div class="row">
        <div class="row_left">
          夏普率：<span>{{ i.sharp_ratio || '-' }}</span>
        </div>
        <div class="row_right">
          历史年化：<span :class="getClass(i.annualized_gain_rate)">{{ i.annualized_gain_rate }}%</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lineChart } from '@/components/echart/index'
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    item: {
      type: Object,
      default:()=>({})
    }
  },
  methods: {
    // 画图
    drawEchart(){
      const { strategy_detail, strategy_id } = this.item
      lineChart({
        dom: `#echart_${strategy_id}`,
        source: (()=>{
          if (strategy_detail.line_chart) {
            return {
              date: strategy_detail.line_chart[0].list.map(i=>i.date),
              target: strategy_detail.line_chart[0].list.map((i, ind, arr)=>i.value/arr[0].value),
              csi: strategy_detail.line_chart[1].list.map((i, ind, arr)=>i.value/arr[0].value)
            }
          } else {
            return {}
          }
        })()
      })
    },

    // 设置颜色
    getClass(num){
      num=parseFloat(num)
      if (num>0) {
        return 'red'
      } else if (num<0) {
        return 'green'
      }
    }
  },
  mounted() {
    this.drawEchart()
  }
})
</script>
<style lang="scss" scoped>
.tactics_item{
  height: 360px;
  width: 360px;
  // height: 420px;
  width: 410px;
  background: #fff;
  border-radius: 10px;  
  padding: 0 8px 0 8px;
  box-sizing: border-box;
  position: relative;

  .title{
    max-width: 100%;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgb(85, 103, 127);
    
    .title_left{
      flex: 1;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      max-width: 60%;
      span{
        color: rgb(196, 90, 94);
      }
    }
    .title_right{
      z-index: 1;
      position: absolute;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      text-align: right;
      right: 14px;
      top: 5px;
      line-height: 1.2;

      span:last-child{
        font-size: 14px;
        // text-align: center;
      }
      span:first-child{
        font-size: 36px;
        font-weight: bold;
      }
    }
  }

  .inner{
    padding: 0 10px;
    box-sizing: border-box;
  }
  .echart{
    height: 180px;
    // background: red;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .about{
    margin-bottom: 16px;
    font-size: 15px;
    color: rgb(85, 103, 127);
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

  }
  .row{
    margin-bottom: 8px;
    font-size: 15px;
    display: flex;
    padding: 0 10px;
    .row_left, .row_right{
      flex: 1;

    }
    span{
      // color: rgb(196, 90, 94);
    }
    
  }
  .row:not(:last-child){
    margin-bottom: 8px;
  }

  .red{
    color: rgb(196, 90, 94);
    // color: red;
  }
  .green{
    color: #093;
  }
}
</style>